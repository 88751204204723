/** @jsx jsx */
import { jsx } from 'theme-ui'
import React from 'react'
import { get, isFunction } from 'lodash'
import { graphql } from 'gatsby'

import Link from '../components/link'
import PageWrapper from '../components/page-wrapper'

import { Cont, TextBlock, FlexCols, FlexCol, Space } from '../styles/els'

const ErrorPage = (props) => {
  return (
    <PageWrapper>
      <Cont
        sx={{
          pt: 4,
          pb: 4,
        }}
      >
        <Space />
        <FlexCols>
          <FlexCol across={2} base={3}>
            <TextBlock
              sx={{
                variant: 'textBlock.serifBody',
                textAlign: 'left',
                pr: 4,
                mt: '-4px',
              }}
            >
              <p>
                Page not found, visit the <Link to="/">homepage</Link>.
              </p>
            </TextBlock>
          </FlexCol>
        </FlexCols>
      </Cont>
    </PageWrapper>
  )
}

export default ErrorPage
